import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const AboutPage = () => (
  <Layout>
    <SEO title='About' />
    <h1>About</h1>
    <h2>The world of optics</h2>
    <p>
      ViSpecs offers a optimal advice in advance for your glasses or contact
      lens search. Surf and discover the latest frames and collections.
    </p>
    <h2>Your personalized glasses</h2>
    <ul>
      <li>
        Start in advance with the selection of the glasses frame from a huge
        range of different frame manufacturers or search for your suitable
        contact lenses.
      </li>
      <li>
        The following personalized needs analysis in combination with your
        optical data enables you to find an optimal lens (short, wide or
        progressive lenses).
      </li>
      <li>
        After checking the data, the quotation created is forwarded to your
        desired, nearest partner optician in order to carry out the fine
        adjustment on site.
      </li>
      <li>
        Experience a mobile, unique online services combined with stationary
        expertise.
      </li>
    </ul>
    <h2>Advantages and benefits</h2>
    <ul>
      <li>
        Digital glasses passport: Storage of your optical data (diopter) and the
        glasses frame directly in the app
      </li>
      <li>
        Personal digital optician: trends, news, preventative appointments,
        personalized recommendations for glasses, frames, contact lenses and
        much more via push messages
      </li>
      <li>Optician Finder: Find your optician nearby </li>
      <li>
        Terminals: Every partner optician has a stationary ViSpecs Consultation
        terminal to carry out the needs analysis, if desired, directly on site
      </li>
    </ul>
    <p>
      <stron>Become part of the ViSpecs universe</stron> and convince yourself
      of the digital stationary solution for an optimal viewing experience.
    </p>
  </Layout>
)

export default AboutPage
